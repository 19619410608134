import { apiUpload } from "@/api";
import { imagePath as $vImagesPath } from "@/common/utilsFilters";
import { errorHandleMessage } from "@/common/noticeMessageState";

export default class UploadAdapter {
  constructor(loader) {
    this.loader = loader;
  }

  upload() {
    return this.loader.file.then(
      (file) =>
        new Promise((resolve) => {
          this.sendRequest(file, resolve);
        }),
    );
  }

  async sendRequest(file, resolve) {
    this.data = new FormData();
    this.data.append("file", file);
    const { size } = file;
    const limitMaxSize = 1024 * 1024;
    if (size >= limitMaxSize) {
      errorHandleMessage("圖片大小不得超過1GB");
      resolve({ default: "" });
    } else {
      try {
        const res = await apiUpload(this.data);
        const { code, data } = res.data;
        if (code === 200) {
          const { imagePath } = data;
          resolve({ default: $vImagesPath(imagePath) });
        }
      } catch (e) {
        errorHandleMessage(e);
      }
    }
  }
}
