<template>
  <VForm @submit="createUpdateContent" ref="form" v-slot="{ errors }">
    <div class="modal-body">
      <div class="row g-2">
        <div class="col-12">
          <label for="title" class="form-label">標題*</label>
          <div class="input-group">
            <VField
              name="標題"
              type="text"
              id="title"
              class="form-control border"
              :class="{ 'is-invalid': errors['標題'] }"
              placeholder="輸入標題"
              maxLength="50"
              v-model="articleContent.title"
              rules="required|max:50"
            />
            <span class="input-group-text bg-white fs-9 text-info p-2"
              >{{ articleContent.title?.length }}/50</span
            >
          </div>
        </div>
        <div class="col-12">
          <label for="breif" class="form-label">內文描述*</label>
          <div class="input-group">
            <VField
              name="內文描述"
              type="text"
              id="brief"
              class="form-control border"
              :class="{ 'is-invalid': errors['內文描述'] }"
              maxLength="100"
              v-model="articleContent.brief"
              rules="max:100"
            >
            </VField>
            <span class="input-group-text bg-white fs-9 text-info p-2"
              >{{ articleContent.brief?.length || 0 }}/100</span
            >
          </div>
        </div>
        <div class="col-4">
          <label for="author" class="form-label">作者*</label>
          <VField
            name="作者"
            v-model="articleContent.author"
            v-slot="{ field }"
            rules="required|max:10"
          >
            <input
              type="text"
              id="author"
              class="form-control border"
              :class="{ 'is-invalid': errors['作者'] }"
              maxLength="10"
              v-bind="field"
            />
          </VField>
        </div>
        <div class="col-3 mb-3">
          <label for="title" class="form-label">文章分類*</label>
          <select v-model="articleContent.categoryId" class="form-select" aria-label=".form-select">
            <option v-for="type in selectCategory" :key="type.key" :value="type.id">
              {{ type.name }}
            </option>
          </select>
        </div>
        <div class="col-6" v-if="uploadCoverImg">
          <div
            class="w-100 bg-light mb-2"
            :class="articleContent.imagePath ? 'show' : ''"
            style="height: 100px"
          >
            <img
              v-if="articleContent.imagePath"
              :src="$imageFilters(articleContent.imagePath)"
              alt="圖片"
              style="max-height: 100%; object-fit: contain"
            />
            <p v-else class="pt-6 text-center">上傳封面圖</p>
          </div>
        </div>
        <div class="col-6" v-if="uploadCoverImg">
          <AppUpload @uploadSuccess="uploadSuccess" title="文章封面" />
        </div>
      </div>

      <div class="row g-2 mt-3">
        <h4 class="fs-5 mb-1">文章內容</h4>
        <ckeditor
          :editor="editor"
          :config="editorConfig"
          v-model="articleContent.content"
          @ready="onReady"
        ></ckeditor>
      </div>
      <div class="row g-2">
        <div class="col d-flex align-items-center mt-3">
          <label class="form-label m-0" for="article-active">是否公開</label>
          <div class="switch-group ms-2">
            <input
              type="checkbox"
              id="article-active"
              role="button"
              v-model="articleContent.isPublished"
            />
            <div class="ico_switch"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-outline-dark" @click="$emit('cancel')">取消</button>
      <button type="submit" class="btn btn-primary" :disabled="!isAllowFire">儲存</button>
    </div>
  </VForm>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
// import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import DecoupledEditor from "@ckeditor/ckeditor5-build-decoupled-document";

import UploadAdapter from "@/common/uploadAdapter";
import { DatePicker } from "v-calendar";
import { apiUpdateArticle, apiCreateArticle, apiGetAdminArticle } from "@/api/article";
import { ARTICLE_TYPES, NEWS_INFO, SOCIAL_RESPONSE, SOLUTION } from "@/constants/article";

export default {
  component: {
    DatePicker,
  },
  inject: ["emitter"],
  props: {
    modalData: Object,
    type: {
      type: String,
      validator: (val) => {
        return [NEWS_INFO.key, SOLUTION.key, SOCIAL_RESPONSE.key].indexOf(val) !== -1;
      },
    },
    uploadCoverImg: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      articleContent: {
        title: "",
        author: "",
        isPublished: false,
        content: "",
        categoryId: "",
        alternativeText: "",
        imageId: "",
        imagePath: "",
      },
      articleTypes: ARTICLE_TYPES,
      editor: DecoupledEditor,
      editorConfig: {
        toolbar: [
          "heading",
          "|",
          "bold",
          "italic",
          "fontSize",
          "fontColor",
          "fontBackgroundColor",
          "bulletedList",
          "numberedList",
          "|",
          "alignment",
          "link",
          "|",
          "uploadImage",
          "blockQuote",
          "undo",
          "redo",
        ],
        extraPlugins: [this.uploader],
      },
    };
  },
  computed: {
    ...mapGetters("category", ["getCategory"]),
    isAllowFire() {
      const { title, author, content } = this.articleContent;
      return title && author && content;
    },
    selectCategory() {
      return this.getCategory(this.type);
    },
    initCategoryId() {
      if (this.selectCategory.length > 0) {
        return this.selectCategory[0].id;
      } else {
        return "";
      }
    },
  },
  methods: {
    ...mapActions("category", ["queryCategory"]),
    async getContent(articleUuid) {
      try {
        this.$vLoading(true);
        this.articleContent.articleUuid = articleUuid;
        const res = await apiGetAdminArticle(articleUuid);
        const { code, data, message } = res.data;
        if (code === 200) {
          this.articleContent = { articleUuid, ...data };
        } else {
          this.$vHttpsNotice({ statusCode: code, title: message });
        }
      } catch (error) {
        this.$vErrorNotice();
      } finally {
        this.$vLoading(false);
      }
    },
    async createUpdateContent() {
      if (this.articleContent.content.length === 0) {
        this.emitter.emit("notice-message", { style: "warning", title: "請輸入內容" });
        return;
      }
      try {
        const { articleUuid } = this.articleContent;
        const res = articleUuid
          ? await apiUpdateArticle(articleUuid, this.articleContent)
          : await apiCreateArticle(this.articleContent);
        this.$vHttpsNotice({
          statusCode: res.status,
          title: `${articleUuid ? "編輯" : "新增"}文章成功`,
        });
        this.$emit("finishContent");
      } catch (error) {
        this.$vErrorNotice();
      }
    },
    uploadSuccess(val) {
      const { imageId, imagePath } = val;
      this.articleContent.alternativeText = `文章id:${imageId}`;
      this.articleContent.imageId = imageId;
      this.articleContent.imagePath = imagePath;
    },
    uploader(editor) {
      const { plugins } = editor;
      plugins.get("FileRepository").createUploadAdapter = (loader) => new UploadAdapter(loader);
    },
    onReady(editor) {
      editor.ui
        .getEditableElement()
        .parentElement.insertBefore(editor.ui.view.toolbar.element, editor.ui.getEditableElement());
    },
  },
  watch: {
    modalData(val) {
      if (val.articleUuid) {
        this.$nextTick(() => {
          this.getContent(val.articleUuid);
        });
      } else {
        this.$refs.form.resetForm();
        this.articleContent = {
          title: "",
          author: "",
          isPublished: false,
          content: "",
          categoryId: this.initCategoryId,
          alternativeText: "",
          imageId: "",
          brief: "",
        };
      }
    },
  },
  created() {
    if (this.selectCategory.length === 0) {
      this.queryCategory({ type: this.type });
    }
    this.articleContent.categoryId = this.initCategoryId;
  },
};
</script>

<style lang="scss">
.ck-toolbar {
  width: 100% !important;
}
.ck-content {
  min-height: 30vh !important;
  height: 48vh;
  overflow: auto;
  margin-top: -1px !important;
  border: 1px solid var(--ck-color-toolbar-border) !important;
}
</style>
