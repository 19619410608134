<template>
  <div class="container-fluid">
    <div class="d-flex justify-content-between mb-5">
      <AppSearch title="文章名稱" @searchItem="(val) => querySearch(val)" />
      <button class="btn btn-primary ms-auto btn-sm" type="button" @click="openModal('create')">
        <i class="bi bi-plus-square-fill" style="top: -3px"></i>
        新增文章
      </button>
    </div>

    <p v-if="searchTitle" class="fs-9 text-info mb-2">
      搜尋/{{ searchTitle }}
      <i class="bi bi-x fs-9 cursor-pointer" @click="querySearch('')"></i>
    </p>
    <AppTable :columns="columns" :values="articles" :pages="pages" @changePage="changePage">
      <template #categoryId="props">
        <p class="px-2">{{ typeName(props.data.categoryId) }}</p>
      </template>
      <template #isPublished="props">
        <i class="bi bi-check-circle-fill fs-5 text-success" v-show="props.data.isPublished"></i>
      </template>
      <template #action="props">
        <div class="d-flex justify-content-center">
          <button
            class="btn btn-sm btn-outline-primary fs-7"
            type="button"
            @click="openModal('edit', props.data)"
          >
            編輯
          </button>
          <button
            class="btn btn-sm btn-outline-danger fs-7 ms-2 ms-md-3"
            type="button"
            @click="openModal('delete', props.data)"
          >
            刪除
          </button>
        </div>
      </template>
    </AppTable>

    <AppModal
      v-model="showArticleModal"
      refName="articleModal"
      :title="modalData.articleId ? '編輯文章' : '新增文章'"
    >
      <ArticleModal
        :type="type"
        :modal-data="modalData"
        :upload-cover-img="uploadCoverImg"
        @finishContent="finishContent"
        @cancel="showArticleModal = false"
      />
    </AppModal>

    <AppModal v-model="showQuestionModal" refName="questionModal" size="md" title="刪除">
      <QuestionModal
        :content="`確定刪除${modalData.title} ?`"
        @checkInfo="deleteArticle(modalData.articleUuid)"
      />
    </AppModal>
  </div>
</template>

<script>
import ArticleModal from "@/components/ArticleModal.vue";
import QuestionModal from "@/components/QuestionModal.vue";
import { apiQueryAdminArticle, apiRemoveArticle } from "@/api/article";
import { NEWS_INFO, SOLUTION, SOCIAL_RESPONSE } from "@/constants/article";
import { mapGetters } from "vuex";
import AppModal from "@/components/AppModal.vue";
import AppTable from "@/components/AppTable.vue";
import AppSearch from "@/components/AppSearch.vue";

export default {
  components: {
    AppSearch,
    AppTable,
    AppModal,
    ArticleModal,
    QuestionModal,
  },
  props: {
    type: {
      type: String,
      validator: (val) => {
        return [NEWS_INFO.key, SOLUTION.key, SOCIAL_RESPONSE.key].indexOf(val) !== -1;
      },
    },
    uploadCoverImg: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      columns: [
        { name: "createTime", label: "日期", className: "col-2" },
        { name: "title", label: "文章名稱", className: "col-3" },
        { name: "author", label: "作者" },
        { name: "categoryId", label: "類別" },
        { name: "isPublished", label: "啟用", className: "text-center" },
        { name: "action", label: "" },
      ],
      showArticleModal: false,
      showQuestionModal: false,
      questionModalContent: "",
      targetItemId: null,
      articles: [],
      modalData: {},
      pages: {
        totalPages: 1,
        currentPage: 1,
      },
      tempTitle: "",
      searchTitle: "",
    };
  },
  computed: {
    ...mapGetters("category", ["getCategory"]),
    queryParams() {
      const { currentPage } = this.pages;
      const title = this.searchTitle ? { title: this.searchTitle } : {};
      return {
        ...title,
        page: currentPage,
        type: this.type,
      };
    },
    typeName() {
      return (categoryId) =>
        this.getCategory(this.type).find((i) => i.id === categoryId)?.name || categoryId;
    },
  },
  methods: {
    async queryArticles() {
      this.$vLoading(true);
      try {
        const res = await apiQueryAdminArticle(this.queryParams);
        const { code, data, message } = res.data;
        if (code === 200) {
          const { content, pageInfo } = data;
          const { totalPages, currentPage } = pageInfo;
          this.articles = content;
          this.pages = { totalPages, currentPage };
        } else {
          this.$vHttpsNotice({ statusCode: code, title: message });
        }
      } catch (error) {
        this.$vErrorNotice();
      } finally {
        this.$vLoading(false);
      }
    },
    async deleteArticle(articleUuid) {
      this.$vLoading(true);
      try {
        await apiRemoveArticle(articleUuid);
        this.queryArticles();
        this.modalData = {};
        this.showQuestionModal = false;
      } catch (error) {
        // regardless
        this.$vErrorNotice();
      } finally {
        this.$vLoading(false);
      }
    },
    finishContent() {
      this.showArticleModal = false;
      this.modalData = {};
      this.queryArticles();
    },
    openModal(status, item) {
      this.modalData = item || {};
      if (status === "create" || status === "edit") {
        this.showArticleModal = true;
      } else if (status === "delete") {
        this.showQuestionModal = true;
      }
    },
    changePage(page) {
      this.pages.currentPage = page;
      this.queryArticles();
    },
    querySearch(val) {
      this.searchTitle = val;
      this.pages.currentPage = 1;
      this.queryArticles();
    },
  },
  created() {
    this.queryArticles();
  },
};
</script>
